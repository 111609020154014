import { provideHttpClient, withXsrfConfiguration, withInterceptorsFromDi, HTTP_INTERCEPTORS, HttpXsrfTokenExtractor } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, inject } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';

import { provideFuse } from '@fuse';

import { Ability, PureAbility } from '@casl/ability';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';

import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { provideAuth } from 'app/core/auth/auth.provider';
import { firstValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { CustomDatePipe } from './shared/pipes/custom-date.pipe';
import { environment } from 'environments/environment';
import { XsrfInterceptor } from './shared/interceptors/xsrf.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { providePrimeNG } from 'primeng/config';
import Material from '@primeng/themes/material';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withXsrfConfiguration({
            cookieName: 'XSRF-TOKEN', // default is 'XSRF-TOKEN'
            headerName: 'X-XSRF-TOKEN' // default is 'X-XSRF-TOKEN'
          }),
        withInterceptorsFromDi(),),
        { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true},
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        [CookieService],
        [CustomDatePipe],
        [DatePipe],
        [CurrencyPipe],
        [DecimalPipe],
        { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'dd/MM/yyyy',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Permission Based Config
        { provide: Ability, useValue: new Ability() },
        { provide: PureAbility, useExisting: Ability },

        // for TinyMCE
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },

        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey, },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'tr',
                        label: 'Turkish',
                    },
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi: true,
        },

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classic',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-amex',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-amex',
                        name: 'Amex',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        providePrimeNG({ 
            theme: {
                preset: Material,
                options: {
                    darkModeSelector: false
                }
            }
        })
    ],
};
